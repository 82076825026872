import { NOTIFICATION_FRAGMENT, NOTIFICATION_SETTINGS_FRAGMENT, READ_NOTIFICATION_FRAGMENT } from '#notification/fragment'

export const NOTIFICATIONS_QUERY = gql`
  ${NOTIFICATION_FRAGMENT}

  query NotificationsQuery($workspaceId: ID!, $filter: NotificationFilter) {
    notifications(workspaceId: $workspaceId, filter: $filter) {
      ...Notification
    }
  }
`

export const READ_NOTIFICATION_MUTATION = gql`
  ${READ_NOTIFICATION_FRAGMENT}
  mutation ReadNotificationMutation($notificationId: ID!, $isRead: Boolean!) {
    readNotification(notificationId: $notificationId, isRead: $isRead) {
      success
      notification {
        ...ReadNotification
      }
    }
  }
`

export const READ_ALL_NOTIFICATION_MUTATION = gql`
  ${READ_NOTIFICATION_FRAGMENT}
  mutation ReadAllNotificationsMutation($workspaceId: ID!) {
    readAllNotifications(workspaceId: $workspaceId) {
      success
      notifications {
        ...ReadNotification
      }
    }
  }
`

export const NOTIFICATION_SETTINGS_QUERY = gql`
  ${NOTIFICATION_SETTINGS_FRAGMENT}
  query NotificationSettingsQuery($workspaceId: ID!) {
    notificationSettings(workspaceId: $workspaceId) {
      ...NotificationSettings
    }
  }
`

export const UPDATE_SETTINGS_MUTATION = gql`
  ${NOTIFICATION_SETTINGS_FRAGMENT}
  mutation UpdateNotificationSettings($input: UpdateSettingsInput!) {
    updateNotificationSettings(input: $input) {
      settings {
        ...NotificationSettings
      }
    }
  }
`

export const UNREAD_NOTIFICATIONS_COUNT_QUERY = gql`
  query UnreadNotificationsCountQuery($workspaceId: ID!) {
    unreadNotificationsCount(workspaceId: $workspaceId)
  }
`
