import type {
  NotificationFilter,
  NotificationSettings,
  NotificationType,
} from '#notification/types'
import {
  UPDATE_SETTINGS_MUTATION,
  NOTIFICATION_SETTINGS_QUERY,
  NOTIFICATIONS_QUERY,
  READ_ALL_NOTIFICATION_MUTATION,
  READ_NOTIFICATION_MUTATION,
  UNREAD_NOTIFICATIONS_COUNT_QUERY,
} from '#notification/schema'

export const useNotificationSettingsQuery = (workspaceId: string) => {
  return useQuery<{ notificationSettings: NotificationSettings }>(
    NOTIFICATION_SETTINGS_QUERY, { workspaceId }
  )
}

export const useUpdateNotificationSettingsMutation = () => {
  return useMutation<{
    updateNotificationSettings: {
      settings: NotificationSettings
    }
  }>(UPDATE_SETTINGS_MUTATION)
}

export const useLazyNotificationsQuery = (workspaceId: string, filter?: NotificationFilter) => {
  return useLazyQuery<{ notifications: NotificationType[] }>(
    NOTIFICATIONS_QUERY,
    { workspaceId, filter }
  )
}

export const useReadNotificationMutation = () => {
  return useMutation<{
    readNotification: { success: boolean; notification: NotificationType }
  }>(READ_NOTIFICATION_MUTATION)
}

export const useReadAllNotificationMutations = () => {
  return useMutation<{
    readAllNotifications: {
      success: boolean
      notifications: Array<NotificationType>
    }
  }>(READ_ALL_NOTIFICATION_MUTATION)
}

export const useLazyUnreadNotificationsCountQuery = (workspaceId: string) => {
  return useLazyQuery<{ unreadNotificationsCount: number }>(
    UNREAD_NOTIFICATIONS_COUNT_QUERY,
    { workspaceId }
  )
}
