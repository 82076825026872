import { MIN_ACTION_FRAGMENT } from '#action/fragment'

export const READ_NOTIFICATION_FRAGMENT = gql`
  fragment ReadNotification on NotificationType {
    id
    isRead
  }
`

export const NOTIFICATION_FRAGMENT = gql`
  ${MIN_ACTION_FRAGMENT}

  ${READ_NOTIFICATION_FRAGMENT}
  fragment Notification on NotificationType {
    id
    ...ReadNotification
    actions {
      ...MinAction
    }
  }
`

export const NOTIFICATION_SETTINGS_FRAGMENT = gql`
  fragment NotificationSettings on SettingsType {
    id
    emailFrequency
    emailRelate
    emailDailySummary
    slackEnabled
    slackConnection {
      id
      account
    }
    slackRelate
    slackDailySummary
  }
`
